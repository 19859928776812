import {useState, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
// Styles
import styles from './index.module.scss';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { GridLoader } from "react-spinners";
import Cookies from 'universal-cookie';

//Views
import {SideMenuItem} from '../SideMenuItem';

//Services & Local Logic

// Assets
import logoLight from '../../images/logo-main.png';

import bookingsIcon from '../../images/booking-icon.png';
import warehouseIcon from '../../images/warehouse-icon.png';
import vehicleIcon from '../../images/vehicle-icon.png';
import priceIcon from '../../images/price-icon.png';
import routeIcon from '../../images/route-icon.png';
import logoutIcon from '../../images/logout-icon.png';
import customersIcon from '../../images/client-icon.png';
import employeesIcon from '../../images/employee-icon.png';
import integrationsIcon from '../../images/integrations-icon.png';
import organisationIcon from '../../images/organisation-icon.png';
import snippetIcon from '../../images/snippet.png';
import newsIcon from '../../images/news-icon.png';
import messageIcon from '../../images/message-icon.png';
import zoneIcon from '../../images/zone-icon-white.png';
import storeIcon from '../../images/store-design.png';
import settingsIconWhite from '../../images/settings-icon-white.png';
import bellIconWhite from '../../images/bell-icon-white.png';
import productIcon from '../../images/product-icon.png';
import saleIcon from '../../images/sale-icon.png';
import pricingIcon from '../../images/pricing-icon.png';
import calendarIcon from '../../images/calendar-icon.png';

import warehouseIconBlue from '../../images/warehouse-icon-blue.png';
import vehicleIconBlue from '../../images/vehicle-icon-blue.png';
import priceIconBlue from '../../images/price-icon-blue.png';
import customersIconBlue from '../../images/client-icon-blue.png';
import employeesIconBlue from '../../images/employees-icon-blue.png';
import integrationsIconBlue from '../../images/integrations-icon-blue.png';
import organisationIconBlue from '../../images/organisation-icon-blue.png';
import snippetIconBlue from '../../images/snippet-icon-blue.png';
import newsIconBlue from '../../images/news-icon-blue.png';
import messageIconBlue from '../../images/message-icon-blue.png';
import zoneIconBlue from '../../images/zone-icon-blue.png';

// Animations
import Fade from 'react-reveal/Fade';


export const SideMenu = ({placeholder,onChange,name,activeScreen})=>{
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const logout = async()=>{
    const cookies = new Cookies();
    let expiryDate = new Date(new Date().getTime() - (9 * 24 * 3600 * 1000));
    await cookies.set('token', '', { path: '/', expires:expiryDate});
    await cookies.set('profile', '', { path: '/', expires:expiryDate});
    navigate('/');
  }

  return(
    <div class={styles.sideMenu}>
      <img className={styles.sideMenuLogo} src={logoLight} />
      <br/>
      <div className={styles.sideMenuContent}>
        <SideMenuItem href="../dashboard" text="My Clinics" icon={warehouseIcon} isActive={(activeScreen === "Home")} />
        <SideMenuItem href="./patients" text="My Patients" icon={zoneIcon} isActive={(activeScreen === "Patients")} />
        <SideMenuItem href="./bookings" text="My Bookings" icon={zoneIcon} isActive={(activeScreen === "Bookings")} />
        <SideMenuItem href="./timeslots" text="My Timeslots" icon={newsIcon} isActive={(activeScreen === "Timeslots")} />
      </div>
      <div className={styles.sideMenuLogoutButton} onClick={logout}>
        <div className={styles.sideMenuLogoutButtonText}>Logout</div>
      </div>
    </div>
  )


}
