import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import styles from './styles.module.scss';
import logoDark from "../../images/logo-main.png";

import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { resetPassword } from "../../services";
import Cookies from 'universal-cookie';

export const ResetPassword = () => {
  const [user, setUser] = useRecoilState(userState);
  const [password, setPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [email, setEmail] = useState('');
  const [error,setError] = useState('');
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const setEmailFromParams = async()=>{
    const em = await searchParams.get("e");
    await setEmail(em)
  }

  useEffect(()=>{
    const cookies = new Cookies();
    const token = cookies.get('token');
    setEmailFromParams()
    if(token){
      navigate('/dashboard');
    }
  },[]);

  const onSubmit = useCallback(
    async(e) => {
      e.preventDefault();

      try{
        const code = await searchParams.get("c")
        const email = await searchParams.get("e")
        const loginResult = await resetPassword({password:newPassword,code,email});

        if(loginResult.data.success){
            alert('Password Reset!')
            navigate('/dashboard');
        }else{
          setError(loginResult.data.error);
          console.log(loginResult.data);
        }
      }catch (error){
        setError(error.message);
      }




    },
    [user, newPassword, resetPassword]
  )



  return (
    <div className="global-container">
      <div className={styles.loginContainer}>


        <div className={styles.loginColumn}>
            <div className="global-login-card-view" id={styles.loginPanel}>
              <img src={logoDark} className={styles.loginLogo} />
              <br/>
              <div className={styles.resetHeader}>Reset Password: {email}</div>
              <br/>
              <TextInput placeholder={'New Password'} type={'text'} onChange={(value)=>{setNewPassword(value)}} />

              <div className={styles.submitButtonContainer}>
                <SubmitButton loading={loading} title={"Reset"} onClick={onSubmit} />
              </div>

              <div className={styles.loginError}>{error}</div>
              <br/>
              <div className={styles.loginLinks}>
              </div>
            </div>

            <div className={styles.loginCopyright}>
              	&copy; Copyright BUCKEYE HILLS LTD 2022
            </div>
        </div>
      </div>
    </div>
  )
}
