import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { ClipLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import TimePicker from "rc-time-picker";
import {getClinics, getTimeslots, getPatientsBySearch, getBookings, createBooking, updateBooking, sendConfirmationEmails, sendReminderEmails, updateRecord, getMedicsBySearch, getSolicitorsBySearch, resendEmails, getMedicRecords,getProfileDiscover, getUserDetailsService, getRecentBookings, getSellerProductDetails,createOrder} from '../../services/';
import styles from './styles.module.scss';

import logoDark from "../../images/logistible-commerce.png";
import searchIcon from '../../images/search-icon.png';
import searchIconGrey from '../../images/search-icon-grey.png';
import settingsIcon from "../../images/settings-icon.png";
import bellIcon from "../../images/bell-icon.png";
import messagesIcon from "../../images/messages-icon.png";
import profileURL from "../../images/default-profile.png";
import dotMenu from '../../images/dot-menu.png';
import alertIconPink from '../../images/alert-icon-pink.png';
import productIconPink from '../../images/package-icon-pink.png';
import messageIconPink from '../../images/message-icon-pink.png';
import filterIcon from '../../images/filter.png';
import downloadIcon from '../../images/download.png';
import pageArrowLeft from '../../images/page-arrow-left.png';
import pageArrowRight from '../../images/page-arrow-right.png';
import noOrders from '../../images/no-orders-graphic.png';
import plusIcon from '../../images/plus-icon.png';
import removeIcon from '../../images/remove-icon.png';
import xButton from '../../images/x-button.png';

import dashboardWidgetCart from '../../images/dashboard-widget-cart.png';
import dashboardWidgetOrders from '../../images/dashboard-widget-orders-icon.png';
import dashboardWidgetSales from '../../images/dashboard-widget-sales.png';
import dashboardWidgetStore from '../../images/dashboard-widget-store.png';
import dashboardCustomerFeedback from '../../images/dashboard-customer-feedback.png';

import { useSelector } from 'react-redux';
import { setProfile } from '../../redux/authSlice.js';
import store from '../../redux/store.js';

import moment from 'moment';

const TIMES = [
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00'
]

const MONTHS = [
  {name:'January',short:"Jan",color:'#2A7EFF'},
  {name:'February',short:"Feb",color:'#00E976'},
  {name:'March',short:"Mar",color:'#85FF15'},
  {name:'April',short:"Apr",color:'#DF0051'},
  {name:'May',short:"May",color:'#FF6235'},
  {name:'June',short:"Jun",color:'#001994'},
  {name:'July',short:"Jul",color:'#35D4FF'},
  {name:'August',short:"Aug",color:'#7100D4'},
  {name:'September',short:"Sept",color:'#00554F'},
  {name:'October',short:"Oct",color:'#C02AFF'},
  {name:'November',short:"Nov",color:'#DF0000'},
  {name:'December',short:"Dec",color:'#986AFF'},
]

const SHORT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]


const STATUS_LIST = [
  'Pending',
  'Confirmed',
  'Cancelled'
]

export const Bookings = () => {
  const [user, setUser] = useState({});
  const [search,setSearch] = useState('');
  const [searchDate,setSearchDate] = useState(null);
  const [error,setError] = useState('');
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading,setLoading] = useState(true);
  const [token,setToken] = useState(null);
  const [loadingForm,setLoadingForm] = useState(false);
  const [newBookingOpen,setNewBookingOpen] = useState(false);
  const [orderProductsCount, setOrderProductsCount] = useState(0);
  const navigate = useNavigate();

  const newDateRef = useRef(null);
  const [newDate, setNewDate] = useState(new Date());

  const [piab, setPiab] = useState('')
  const [claimantName, setClaimantName] = useState('')
  const [claimantPhone, setClaimantPhone] = useState('')
  const [claimantAddress, setClaimantAddress] = useState('')
  const [appointmentTime, setAppointmentTime] = useState('')
  const [claimantEmail, setClaimantEmail] = useState('')
  const [claimantDOB, setClaimantDOB] = useState('')
  const [incidentDate, setIncidentDate] = useState('')
  const [ldta, setLdta] = useState('')
  const [solicitorName, setSolicitorName] = useState('')
  const [solicitorEmail, setSolicitorEmail] = useState('')
  const [specialNoteToSolicitor, setSpecialNoteToSolicitor] = useState('')
  const [specialNoteToDoctor, setSpecialNoteToDoctor] = useState('')
  const [medicType, setMedicType] = useState('')
  const [solicitorReference, setSolicitorReference] = useState('')
  const [backupSolicitorEmail, setBackupSolicitorEmail] = useState('')
  const [clinicLocation, setClinicLocation] = useState('')
  const [clinicAddress, setClinicAddress] = useState('')
  const [expertEmail, setExpertEmail] = useState('')
  const [medicalExpert, setMedicalExpert] = useState('')
  const [medicId, setMedicId] = useState('')
  const [expertType, setExpertType] = useState('')
  const [appointmentDate, setAppointmentDate] = useState('')
  const [recordId, setRecordId] = useState(null)
  const [recordIndex, setRecordIndex] = useState(null)
  const [sendClientEmail, setSendClientEmail] = useState(true)
  const [sendMedicEmail, setSendMedicEmail] = useState(true)
  const [sendPiabEmail, setSendPiabEmail] = useState(true)
  const [sendSolicitorEmail, setSendSolicitorEmail] = useState(true)
  const [oldDocuments, setOldDocuments] = useState([])
  const [files, setFiles] = useState([])
  const [filesUpdated, setFilesUpdated] = useState(true);
  const [dropdownDate, setDropdownDate] = useState('');
  const [dropdownTime, setDropdownTime] = useState('');
  const appointmentDateRef = useRef(null);
  const appointmentTimeRef = useRef(null);
  const doctorRefEdit = useRef(null);
  const solicitorRefEdit = useRef(null);
  const medicEmailRef = useRef(null);
  const medicTypeRef = useRef(null);
  const clinicLocationRef = useRef(null);
  const clinicAddressRef = useRef(null);
  const solicitorPrimaryEmailRef = useRef(null);
  const solicitorSecondaryEmailRef = useRef(null);
  const solicitorReferenceRef = useRef(null);
  const [bookings, setBookings] = useState([]);
  const [doctors, setDoctors] = useState([])
  const [solicitors, setSolicitors] = useState([])
  const [availableTimes, setAvailableTimes] = useState([])

  const [newBookingName, setNewBookingName] = useState('')
  const [newBookingAddress, setNewBookingAddress] = useState('')
  const [newBookingDob, setNewBookingDob] = useState('')
  const [newBookingEmail, setNewBookingEmail] = useState('')
  const [newBookingPhone, setNewBookingPhone] = useState('')

  const [editBookingName, setEditBookingName] = useState('')
  const [editBookingAddress, setEditBookingAddress] = useState('')
  const [editBookingDob, setEditBookingDob] = useState('')
  const [editBookingEmail, setEditBookingEmail] = useState('')
  const [editBookingPhone, setEditBookingPhone] = useState('')
  const [editBookingId, setEditBookingId] = useState('')

  const [newBookingPatientId, setNewBookingPatientId] = useState(null)
  const [patients, setPatients] = useState([])
  const [clinics, setClinics] = useState([])
  const [newBookingClinic, setNewBookingClinic] = useState([])
  const [newBookingTimeslot, setNewBookingTimeslot] = useState([])

  const [newTimeslotClinic, setNewTimeslotClinic] = useState([])

  const [allTimeslots, setAllTimeslots] = useState([])
  const [currentClinicAvailableTimeslots, setCurrentClinicAvailableTimeslots] = useState([])
  const [newBookingDate, setNewBookingDate] = useState(new Date())
  const [newBookingFee, setNewBookingFee] = useState(new Date())

  const [records, setRecords] = useState([]);

  const getReferralDate = (dateString, time)=>{
    let date = dateString.replaceAll(' ','').replaceAll('.','/').split('/')

    let d = date[0],
      m = parseInt(date[1]) - 1,
      y = date[2];

    let h = time.replaceAll(';',':').replaceAll('pm','').replaceAll('PM','').replaceAll('am','').replaceAll('AM','').split(':')[0],
        min = time.replaceAll(';',':').replaceAll('pm','').replaceAll('PM','').replaceAll('am','').replaceAll('AM','').split(':')[1];

      if(y && y.length == 2) y = '20'+y

    if(!d || !m || !y || !h || !min) return 'error'


    const timeInt = Date.parse(d+' '+SHORT_MONTHS[m]+' '+y+' '+h+':'+min+':00 GMT')

    console.log('TIME: '+timeInt)
    console.log(d+' '+m+' '+' '+y+' '+h+' '+min)
    return timeInt
  }

  const getDate = (date, noTime = false)=>{
    let newDate = new Date(date);
    return newDate.getDate()+" "+(MONTHS[newDate.getMonth()].short)+" "+newDate.getFullYear()
  }

  const getDateTime = (date, noTime = false)=>{
    let newDate = new Date(date);
    return newDate.getDate()+"/"+(newDate.getMonth()+1)+"/"+newDate.getFullYear()+" "+newDate.getHours()+":"+newDate.getMinutes()
  }

  const resendReferralEmails = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');
    await setLoadingForm(true)
    const resendRes = await resendEmails({formId:recordId, sendMedicEmail, sendSolicitorEmail, sendClientEmail, sendPiabEmail},token);
    if(resendRes.data.success){
      alert('Emails Sent');
    }else{
      alert('Error sending emails. Some emails may not have been sent:'+resendRes.data.error);
    }

    await setLoadingForm(false)
  }

  const searchDoctors = async(search, token)=>{
    const doctors = await getMedicsBySearch({token,search});
    if(doctors.data.success){
      setDoctors(doctors.data.medics.map((doctor,doctorIndex)=>{
        return {...doctor}
      }));
    }
  }

  const searchSolicitors = async(search, token)=>{
    if(search.length == 0) return;
    const solicitors = await getSolicitorsBySearch({token,search});
    if(solicitors.data.success){
      setSolicitors(solicitors.data.solicitors.map((solicitor,solicitorIndex)=>{
        return {...solicitor}
      }));
    }
  }

  const updateAppointment = async()=>{
    //setLoading(true);
    let formData = new FormData();
    for(let index = 0; index < files.length;index++){
      formData.append('file'+(index+1), files[index])
    }

    await setLoadingForm(true)

    formData.append('piab', piab)
    formData.append('claimantName', claimantName)
    formData.append('claimantPhone', claimantPhone)
    formData.append('claimantAddress', claimantAddress)
    formData.append('appointmentTime', appointmentTime)
    formData.append('claimantEmail', claimantEmail)
    formData.append('claimantDOB', claimantDOB)
    formData.append('incidentDate', incidentDate)
    formData.append('ldta', ldta)
    formData.append('solicitorName', solicitorName)
    formData.append('solicitorEmail', solicitorEmail)
    formData.append('specialNoteToSolicitor', specialNoteToSolicitor)
    formData.append('specialNoteToDoctor', specialNoteToDoctor)
    formData.append('medicType', medicType)
    formData.append('solicitorReference', solicitorReference)
    formData.append('backupSolicitorEmail', backupSolicitorEmail)
    formData.append('clinicLocation', clinicLocation)
    formData.append('clinicAddress', clinicAddress)
    formData.append('expertEmail', expertEmail)
    formData.append('medicalExpert', medicalExpert)
    formData.append('expertType', expertType)
    formData.append('recordId',  recordId)
    formData.append('appointmentDate', appointmentDate)
    formData.append('medicId', medicId)
    formData.append('oldDocuments', oldDocuments.join(','))

    console.log(recordId);

    const cookies = new Cookies();
    const token = await cookies.get('token');

    const uploadResult = await updateRecord(formData,token);
    if(uploadResult.data.success){
      setFiles([])
      setOldDocuments(uploadResult.data.record.documentURLs.split(','))
      alert('Appointment Saved');

      let recordsTmp = records;
      recordsTmp[recordIndex] = uploadResult.data.record;
      setRecords(recordsTmp)
    }else{
      console.log(uploadResult.data)
      alert("Error saving. Please try again.");
    }
    await setLoadingForm(false)
    setLoading(false);
  }


  const uploadFile = async(event)=>{

    let filesList = files;

    for(let index = 0;index < event.target.files.length;index++){
      let file = event.target.files[index];
      filesList.push(file);
    }

    await setFiles(filesList);
    setFilesUpdated(!filesUpdated);
    console.log(files);
    event.target.value = null;
  }

  const init = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');
    if(!token){
      navigate('/');
    }else{
      setToken(token);
      const loadedBookingsData = await getBookings({token})
      if(loadedBookingsData.data.success){
        setBookings(loadedBookingsData.data.bookings);
        console.log(loadedBookingsData.data.bookings)
      }else{
        alert("Error getting your bookings.");
      }

      const loadedTimeslotsData = await getTimeslots({token})
      if(loadedTimeslotsData.data.success){
        setAllTimeslots(loadedTimeslotsData.data.timeslots);
      }else{
        alert("Error getting your timeslots.");
      }

      const loadedClinicsData = await getClinics({token})
      if(loadedClinicsData.data.success){
        setClinics(loadedClinicsData.data.clinics);
      }else{
        alert("Error getting your clinics.");
      }

      const userDetails = await getUserDetailsService(token,{});
      if(userDetails.data.success){
        setUser(userDetails.data);
      }else{
        console.log(userDetails);
        alert("Error getting your details.");
      }

      const recordDetails = await getMedicRecords({token});
      if(recordDetails.data.success){
        setRecords(recordDetails.data.records.sort((a,b)=>{

          if(getReferralDate(a.appointmentDate, a.appointmentTime) == 'error' && getReferralDate(b.appointmentDate, b.appointmentTime) != 'error') return 1;
          if(getReferralDate(a.appointmentDate, a.appointmentTime) != 'error' && getReferralDate(b.appointmentDate, b.appointmentTime) == 'error') return -1;
          if(getReferralDate(a.appointmentDate, a.appointmentTime) == 'error' && getReferralDate(b.appointmentDate, b.appointmentTime) == 'error') return 0;


          if(getReferralDate(a.appointmentDate, a.appointmentTime) > getReferralDate(b.appointmentDate, b.appointmentTime)){
            return -1
          }else if(getReferralDate(a.appointmentDate, a.appointmentTime) < getReferralDate(b.appointmentDate, b.appointmentTime)){
            return 1
          }

          return 0
        }));
        console.log('RECORDS');
        console.log(recordDetails.data.records)
      }else{
        console.log(recordDetails.data)
        alert("Error getting your records.");
      }


      setLoading(false);
    }

  }


  useEffect(()=>{
    init();
  },[]);


  const renderFormButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <ClipLoader  size={50} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} placeholder="Save" onClick={(event)=>{updateAppointment();}} />
    )
  }

  const renderResendEmailsButton = ()=>{
    if(loadingForm){
      return(
        <div>
          <ClipLoader  size={50} color={"#2e4aff"} />
        </div>
      )
    }

    return(
      <TextInput type="submit-input" icon={plusIcon} large={true} placeholder="Resend Emails" onClick={(event)=>{resendReferralEmails();}} />
    )
  }


  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <ClipLoader  size={30} color={"#147d61"} />
        </div>
      </div>
    )
  }



  const formatDate = (date)=>{
    return date.substr(8,2)+"/"+date.substr(5,2)+"/"+date.substr(0,4)+" ";
  }

  const downloadCSV = ()=>{
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Date,Name,Address,Phone,Email,Price (€)" + "\r\n";
    results.map((result)=>{return([result.orderDate,result.customerName,result.customerAddress,result.customerPhone,result.customerEmail,result.price])}).forEach((rowArray)=>{
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }


  const renderNewBooking = ()=>{
    if(!newBookingOpen) return;

    //Form Should Contain: Timeslot (includes clinic), searched patient, price

    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setNewBookingOpen(false)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>New Booking</div>
            </div>
          </div>
          <div className={styles.ordersForm}>

          <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
            <div className={"global-dashboard-row"}>
              <div style={{marginLeft:"10px", marginRight:"10px"}} className={"global-dashboard-row-card-white"}>

                <div className={styles.cardHeader}>New Booking Details</div>
                <div className={styles.cardColumnContainer}>
                  <div className={styles.cardColumn}>
                  <TextInput placeholder="Patient"  type="form-search" onChangeText={async(value)=>{
                    const cookies = new Cookies();
                    const token = cookies.get('token');

                    let res = await getPatientsBySearch({token, search:value})

                    if(res.data.success){
                      setPatients(res.data.patients)
                    }
                  }} options={patients} onChange={(patient)=>{setNewBookingPatientId(patient.id)}} initialValue={''}/>
                  <TextInput placeholder="Total Fee (€)" noHeader={true} type="form-text" onChange={(value)=>{setNewBookingFee(value)}} initialValue={''}/>

                  </div>
                  <div className={styles.cardColumn}>
                    <TextInput placeholder="Clinic"  type="clinics-dropdown" options={clinics} onChange={(clinic)=>{
                      setNewBookingClinic(clinic)
                    }} initialValue={''}/><br/><br/>
                    <TextInput placeholder="Date Filter" type={'search-date'} onChange={(e)=>{

                      let date = new Date(e)
                      setNewBookingDate(date)

                    }} initialValue={new Date()} />
                    <TextInput placeholder="Timeslots"  type="timeslots-dropdown" options={allTimeslots.filter((t)=>{ return (t.clinicId == newBookingClinic) && (getDate(t.startTime) == getDate(newBookingDate)) })} onChange={(timeslot)=>{setNewBookingTimeslot(timeslot)}} initialValue={''}/>
                    <br/><br/>
                    Or create new timeslot:
                    <TextInput placeholder="Clinic"  type="clinics-dropdown" options={clinics} onChange={(clinic)=>{
                      setNewTimeslotClinic(clinic)
                    }} initialValue={''}/><br/><br/>
                    <TextInput placeholder="Date*" id="abc" type="date" ref={newDateRef} onChange={(value)=>{setNewDate(value)}} initialValue={newDate}/>
                  </div>
                </div>
                <br/>

                <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Save Booking" onClick={async(event)=>{
                  await setLoading(true)
                  const cookies = new Cookies();
                  const token = await cookies.get('token');
                  await setLoadingForm(true)
                  const createRes = await createBooking({timeslot:newBookingTimeslot,patient:newBookingPatientId,fee:newBookingFee, clinicId:newTimeslotClinic, startTime:new Date(newDate).getTime(),token});
                  if(createRes.data.success){
                    setBookings(createRes.data.bookings)
                    await setNewBookingOpen(false)
                  }else{
                    console.log(createRes.data)
                    alert('Error creating booking.');
                  }

                  await setLoading(false)
                }} />

                <br/>
              </div>
            </div>

            <div className={"global-dashboard-row"}>

            </div>
          </div>

            <div className={styles.formButtonContainer}>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderEditBooking = ()=>{
    if(!editBookingId) return;

    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setEditBookingId(false)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>Edit Booking</div>
            </div>
          </div>
          <div className={styles.ordersForm}>

          <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
            <div className={"global-dashboard-row"}>
              <div style={{marginLeft:"10px", marginRight:"10px"}} className={"global-dashboard-row-card-white"}>

                <div className={styles.cardHeader}>Booking Details</div>
                <div className={styles.cardColumnContainer}>
                  <div className={styles.cardColumn}>
                  <TextInput placeholder="Name" noHeader={true} type="form-text" onChange={(value)=>{setEditBookingName(value)}} initialValue={editBookingName}/>
                  <TextInput placeholder="Email" noHeader={true} type="form-text" onChange={(value)=>{setEditBookingEmail(value)}} initialValue={editBookingName}/>
                  <TextInput placeholder="Phone" noHeader={true} type="form-text" onChange={(value)=>{setEditBookingPhone(value)}} initialValue={editBookingPhone}/>

                  </div>
                  <div className={styles.cardColumn}>
                    <TextInput placeholder="Address" noHeader={true} type="form-text" onChange={(value)=>{setEditBookingAddress(value)}} initialValue={editBookingAddress}/>
                    <TextInput placeholder="Date Of Birth" noHeader={true} type="form-text" onChange={(value)=>{setEditBookingDob(value)}} initialValue={editBookingDob}/>

                  </div>
                </div>
                <br/>

                <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Save Booking" onClick={async(event)=>{
                  await setLoading(true)
                  const cookies = new Cookies();
                  const token = await cookies.get('token');
                  await setLoadingForm(true)
                  const updateRes = await updateBooking({dob:editBookingDob,phone:editBookingPhone,email:editBookingEmail,address:editBookingAddress,name:editBookingName,bookingId:editBookingId,token});
                  if(updateRes.data.success){
                    setBookings(updateRes.data.bookings)
                    await setEditBookingId(false)
                    await setEditBookingName('')
                    await setEditBookingAddress('')
                    await setEditBookingPhone('')
                    await setEditBookingEmail('')
                    await setEditBookingDob('')
                  }else{
                    console.log(updateRes.data)
                    alert('Error creating booking.');
                  }

                  await setLoading(false)
                }} />

                <br/>
              </div>
            </div>

            <div className={"global-dashboard-row"}>

            </div>



          </div>

            <div className={styles.formButtonContainer}>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if(token){
    return(
      <div className="global-container">
        {renderNewBooking()}
        {renderEditBooking()}
        <div className={styles.homeContentContainerMain}>

          <SideMenu open={true} activeScreen={"Bookings"} name={user.profileName} />
          <div className={styles.homeDashboardContentMain}>

            <div className="global-header">
              <div className="global-header-profile">
                <img src={user.profileURL || profileURL} className="global-header-profile-image" />
                <div className="global-header-profile-text">{user.name}</div>
              </div>


              <div className="global-header-buttons">
              </div>
            </div>

            <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
              <div className={"global-dashboard-row"}>
                <div style={{marginLeft:"50px", marginRight:"50px", height:"85vh", display:"flex",flexDirection:"column", overflowY:"auto"}} className={"global-dashboard-row-card-white"}>
                  <div className={styles.cardHeader}>
                  <div className={styles.cardHeaderTitle}>
                    <img src={require('../../images/booking-icon.png')} className={styles.cardHeaderIcon} />
                    My Bookings
                  </div>
                  <div className={styles.searchContainer}>
                    <TextInput large={true} style={{marginRight:'10px',marginTop:'30px', verticalAlign:'middle'}} type="submit-input" icon={plusIcon} placeholder="Create Booking" onClick={async(event)=>{
                      setNewBookingOpen(true)
                    }} />
                    <TextInput placeholder="Search" noHeader={true} type="form-text" onChange={(value)=>{setSearch(value)}} initialValue={search}/>

                  </div>
                  </div>
                  <table style={{overflowY:"auto", flex:"1"}}>
                    <tr className={styles.ordersRecentBookingsTableHeader}>
                      <th className={styles.productsResultHeaderItem}>Patient</th>
                      <th className={styles.productsResultHeaderItem}>Clinic</th>
                      <th className={styles.productsResultHeaderItem}>Date</th>
                      <th className={styles.productsResultHeaderItem}>Edit</th>
                    </tr>
                    {
                      (bookings || []).filter((rec,recIndex)=>{
                        if(!searchDate) return true

                        let date = searchDate.split('/')
                        let recDate = (((rec.status == 2)?rec.cancelledDate:rec.appointmentDate) || '').split('/')

                        let dateYear = (date[2]?.length == 4)? date[2].substring(2):date[2]
                        let recDateYear = (recDate[2]?.length == 4)? recDate[2].substring(2):recDate[2]

                        return (parseInt(date[0]) == parseInt(recDate[0]) && parseInt(date[1]) == parseInt(recDate[1]) && dateYear == recDateYear)
                      }).map((record, recordIndex)=>{

                        if(search.length > 0 && !search.toUpperCase().includes(record.name.toUpperCase()) && !record.name.toUpperCase().includes(search.toUpperCase()) && !search.toUpperCase().includes(record.phone.toUpperCase()) && !record.phone.toUpperCase().includes(search.toUpperCase())) return

                        if(recordIndex % 2 != 0){
                          return(
                            <tr className={styles.productsRowEven} style={{backgroundColor:(record.status == 2)?'#ff667d':'#ffffff'}} onClick={async(event)=>{

                            }}>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record['m5_patient']?.name}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record['m5_timeslot']?.['m5_clinic']?.name}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{getDateTime(record['m5_timeslot']?.startTime)}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>
                                <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Edit Booking" onClick={async(event)=>{
                                  setEditBookingId(record.id)
                                  setEditBookingName(record.name)
                                  setEditBookingAddress(record.address)
                                  setEditBookingPhone(record.phone)
                                  setEditBookingEmail(record.email)
                                  setEditBookingDob(record.dob)
                                }} />
                              </td>
                            </tr>
                          )
                        }

                        return(
                          <tr className={styles.productsRowOdd} style={{backgroundColor:(record.status == 2)?'#ff667d':'#f5f6ff'}} onClick={async(event)=>{

                          }}>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record['m5_patient']?.name}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record['m5_timeslot']?.['m5_clinic']?.name}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{getDateTime(record['m5_timeslot']?.startTime)}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>
                              <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Edit Booking" onClick={async(event)=>{
                                setEditBookingId(record.id)
                                setEditBookingName(record.name)
                                setEditBookingAddress(record.address)
                                setEditBookingPhone(record.phone)
                                setEditBookingEmail(record.email)
                                setEditBookingDob(record.dob)
                              }} />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
}


}
