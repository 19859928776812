import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import styles from './styles.module.scss';
import logoDark from "../../images/logo-main.png";

import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { sendResetPasswordEmail } from "../../services";
import Cookies from 'universal-cookie';

export const Reset = () => {
  const [user, setUser] = useRecoilState(userState);
  const [password, setPassword] = useState(null);
  const [error,setError] = useState('');
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(()=>{
    const cookies = new Cookies();
    const token = cookies.get('token');

    if(token){
      navigate('/dashboard');
    }
  },[]);

  const onSubmit = useCallback(
    async(e) => {
      e.preventDefault();

      try{
        const loginResult = await sendResetPasswordEmail({medicEmail:user});

        if(loginResult.data.success){
            alert('Email sent!')
            setUser('')
        }else{
          setError(loginResult.data.error);
          console.log(loginResult.data);
        }
      }catch (error){
        setError(error.message);
      }




    },
    [user, password, sendResetPasswordEmail]
  )



  return (
    <div className="global-container">
      <div className={styles.loginContainer}>


        <div className={styles.loginColumn}>
            <div className="global-login-card-view" id={styles.loginPanel}>
              <img src={logoDark} className={styles.loginLogo} />
              <br/>
              <div className={styles.resetHeader}>Reset Password</div>
              <br/>
              <TextInput placeholder={'Email'} type={'email'} onChange={(value)=>{setUser(value)}} />

              <div className={styles.submitButtonContainer}>
                <SubmitButton loading={loading} title={"Send Email"} onClick={onSubmit} />
              </div>

              <div className={styles.loginError}>{error}</div>
              <br/>
              <div className={styles.loginLinks}>
              </div>
            </div>

            <div className={styles.loginCopyright}>
              	&copy; Copyright BUCKEYE HILLS LTD 2022
            </div>
        </div>
      </div>
    </div>
  )
}
