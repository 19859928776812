import React, { useMemo } from "react";
import {
  ElementsConsumer,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";

import './cardForm.css';




class SplitForm extends React.Component {


  constructor(props){
    super(props);

    this.props = props;
    this.stripe = this.props.stripe;
    this.elements = this.props.elements;

    this.options = {
      style: {
        base: {
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Comfortaa, sans-serif",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }

  }

  handleSubmit = async()=>{

    if (!this.stripe || !this.elements) {
      //this.props.context.setState({payload:{error:"NOT SET UP YET."}});
      //return;
    }

    console.log(this.stripe)

    const payload = await this.stripe.createPaymentMethod({
      type: "card",
      card: this.elements.getElement(CardNumberElement)
    });


    console.log('PAYLOAD')
    console.log(payload)
    return payload;

  };

  render(){
    return (
      <form>
        <label>
          Card number
          <CardNumberElement
            options={this.options}
            onReady={() => {
              console.log("CardNumberElement [ready]");
            }}
            onChange={event => {
              console.log("CardNumberElement [change]", event);
            }}
            onBlur={() => {
              console.log("CardNumberElement [blur]");
            }}
            onFocus={() => {
              console.log("CardNumberElement [focus]");
            }}
          />
        </label>
        <label>
          Expiration date
          <CardExpiryElement
            options={this.options}
            onReady={() => {
              console.log("CardNumberElement [ready]");
            }}
            onChange={event => {
              console.log("CardNumberElement [change]", event);
            }}
            onBlur={() => {
              console.log("CardNumberElement [blur]");
            }}
            onFocus={() => {
              console.log("CardNumberElement [focus]");
            }}
          />
        </label>
        <label>
          CVC
          <CardCvcElement
            options={this.options}
            onReady={() => {
              console.log("CardNumberElement [ready]");
            }}
            onChange={event => {
              console.log("CardNumberElement [change]", event);
            }}
            onBlur={() => {
              console.log("CardNumberElement [blur]");
            }}
            onFocus={() => {
              console.log("CardNumberElement [focus]");
            }}
          />
        </label>
      </form>
    );
  }

};

export default SplitForm;
