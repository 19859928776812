import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../store/user';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import { logout } from "../../services";
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import TimePicker from "rc-time-picker";
import {loadBooking, makePayment} from '../../services/';
import styles from './styles.module.scss';

import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";

import SplitForm from '../../components/cardForm.js';

import logoDark from "../../images/logo-main.png";
import searchIcon from '../../images/search-icon.png';
import searchIconGrey from '../../images/search-icon-grey.png';
import settingsIcon from "../../images/settings-icon.png";
import bellIcon from "../../images/bell-icon.png";
import messagesIcon from "../../images/messages-icon.png";
import profileURL from "../../images/default-profile.png";
import dotMenu from '../../images/dot-menu.png';
import alertIconPink from '../../images/alert-icon-pink.png';
import checkIcon from '../../images/check-icon.png';
import productIconPink from '../../images/package-icon-pink.png';
import messageIconPink from '../../images/message-icon-pink.png';
import filterIcon from '../../images/filter.png';
import downloadIcon from '../../images/download.png';
import pageArrowLeft from '../../images/page-arrow-left.png';
import pageArrowRight from '../../images/page-arrow-right.png';
import noOrders from '../../images/no-orders-graphic.png';
import plusIcon from '../../images/plus-icon.png';
import removeIcon from '../../images/remove-icon.png';
import xButton from '../../images/x-button.png';

import dashboardWidgetCart from '../../images/dashboard-widget-cart.png';
import dashboardWidgetOrders from '../../images/dashboard-widget-orders-icon.png';
import dashboardWidgetSales from '../../images/dashboard-widget-sales.png';
import dashboardWidgetStore from '../../images/dashboard-widget-store.png';
import dashboardCustomerFeedback from '../../images/dashboard-customer-feedback.png';

import { useSelector } from 'react-redux';
import { setProfile } from '../../redux/authSlice.js';
import store from '../../redux/store.js';

import moment from 'moment';

const TIMES = [
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00'
]

const MONTHS = [
  {name:'January',short:"Jan",color:'#2A7EFF'},
  {name:'February',short:"Feb",color:'#00E976'},
  {name:'March',short:"Mar",color:'#85FF15'},
  {name:'April',short:"Apr",color:'#DF0051'},
  {name:'May',short:"May",color:'#FF6235'},
  {name:'June',short:"Jun",color:'#001994'},
  {name:'July',short:"Jul",color:'#35D4FF'},
  {name:'August',short:"Aug",color:'#7100D4'},
  {name:'September',short:"Sept",color:'#00554F'},
  {name:'October',short:"Oct",color:'#C02AFF'},
  {name:'November',short:"Nov",color:'#DF0000'},
  {name:'December',short:"Dec",color:'#986AFF'},
]

const SHORT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]


const STATUS_LIST = [
  'Pending',
  'Confirmed',
  'Cancelled'
]

export const Pay = () => {
  const navigate = useNavigate();

  const getDateTime = (date, noTime = false)=>{
    let newDate = new Date(date);
    return newDate.getDate()+"/"+(newDate.getMonth()+1)+"/"+newDate.getFullYear()+" "+newDate.getHours()+":"+newDate.getMinutes()
  }

  const [booking, setBooking] = useState(null)
  const [medicName, setMedicName] = useState('')
  const [loading, setLoading] = useState(true)
  const [claimantName, setClaimantName] = useState('')
  const [stripeProm, setStripeProm] = useState(null)
  const [payload, setPayload] = useState(null)
  const [paymentComplete, setPaymentComplete] = useState(false)
  const [paymentIntentId, setPaymentIntentId] = useState()
  const cardRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  let lastClick = 0

  const init = async()=>{
    const code = searchParams.get('c');
    const stripePromise = await loadStripe('pk_test_51NwofrJeFMcJLFJXaaTYs9rAgR2DTClk2lcMlK8ThHwIs0326WtkQIyFCVx8LZrdOhv1enRndhprAUDCHR5QbKou00rKVlKuEA');
    setStripeProm({...stripePromise})

      const loadedBookingsData = await loadBooking({code})
      if(loadedBookingsData.data.success){
        console.log(loadedBookingsData.data.booking)
        setBooking(loadedBookingsData.data.booking);
        setMedicName(loadedBookingsData.data.medicName)
      }else{
        alert("Error getting your booking.");
      }

      setLoading(false);
  }


  useEffect(()=>{
    init();
  },[]);

  const submitDetails = async()=>{
    //await setLoading(true)
    if(lastClick && ((new Date().getTime() - lastClick) < 3000)){
      console.log('DOUBLE CLICK');
      return;
    }

    lastClick = new Date().getTime();



    //if(intentId){
      const payload = await cardRef.current.handleSubmit();

      console.log(payload);

      if(payload.error){
        await this.setState({loading:false});
        await this.setState({error:payload.error.message});
        console.log(payload.error.message)
        return;
      }
    //}*/

    console.log('TESt')

    const loadedPaymentData = await makePayment({bookingId:booking.id,paymentMethod:payload.paymentMethod.id})

    if(loadedPaymentData.data.success){
      setPaymentComplete(true)
    }else if(loadedPaymentData.data.actionRequired){
      const { paymentIntent, error } = await stripeProm.confirmCardPayment(
          loadedPaymentData.data.clientSecret
        );
        if (error) return alert("Error in payment, please try again later");
        if (paymentIntent.status === "succeeded"){
          const confirmPaymentData = await makePayment({bookingId:booking.id,check:true,paymentIntentId:loadedPaymentData.data.paymentIntentId})
          if(confirmPaymentData.data.success){
            setPaymentComplete(true)
          }else{
            alert("Error with payment, please contact your medic.")
          }
        }


    }else{
      alert("Error with payment: "+loadedPaymentData.data.error);
    }

    setLoading(false)
  }

  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <GridLoader  size={30} color={"#191919"} />
        </div>
      </div>
    )
  }





    return(
      <div className="global-container">
        <div className={styles.homeContentContainerMain}>

          <div className={styles.homeDashboardContentMain}>

            <div className="global-header">
              <div className="global-header-profile">
                <div className="global-header-profile-text">M5 Appointment Payment</div>
              </div>


              <div className="global-header-buttons">
              </div>
            </div>

            <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
              <div className={"global-dashboard-row"}>
                <div className={styles.appointment}>
                  {
                    (booking)?
                      <div>
                        <img src={logoDark} className={styles.logo} />
                        <div className={styles.appointmentHeader}>Appointment Details</div>
                        <table className={styles.appointmentDetails}>
                          <tr>
                            <td>Name</td>
                            <td>{booking['m5_patient']['name']}</td>
                          </tr>

                          <tr>
                            <td>Date</td>
                            <td>{getDateTime(booking['m5_timeslot']['startTime'])}</td>
                          </tr>

                          <tr>
                            <td>Medic Name</td>
                            <td>{medicName}</td>
                          </tr>

                          <tr>
                            <td>Clinic</td>
                            <td>{booking['m5_timeslot']['m5_clinic']['name']}, {booking['m5_timeslot']['m5_clinic']['address']}</td>
                          </tr>

                          <tr>
                            <td>Fee</td>
                            <td>€{booking['fee']}</td>
                          </tr>

                          <tr>
                            <td>Fee Status: {(booking['paid'] || paymentComplete)?'Paid':'Unpaid'}</td>
                            <td>€{booking['fee']}</td>
                          </tr>
                        </table>
                      </div>
                    :
                    <></>
                  }
                </div>

                <div className={styles.appointmentPayment}>
                  <div className={styles.appointmentHeader}>Appointment Payment</div>
                  <br/>
                  {
                    (paymentComplete || booking['paid'])?
                      <div className={styles.paymentComplete}>
                        <img src={checkIcon} className={styles.paymentCompleteIcon} />
                        <br/>
                        <div className={styles.paymentCompleteText}>
                          Payment Complete!
                        </div>
                      </div>
                    :
                    <>
                      <Elements stripe={stripeProm}>
                        <ElementsConsumer>
                          {({elements, stripe}) => (
                            <SplitForm context={this} setPayload={async(v)=>{
                              await setPayload({...v});

                              console.log(loading)
                            console.log('PAYLOADD'+v) }} ref={cardRef} elements={elements} stripe={stripe} />
                          )}
                        </ElementsConsumer>
                      </Elements>

                      <br/>
                      <div className={styles.submitButton} onClick={(e)=>{submitDetails()}}>
                        Submit
                      </div>
                    </>
                  }

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    )



}
